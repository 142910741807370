/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/codemirror@5.40.2/mode/css/css.min.js
 * - /npm/codemirror@5.40.2/mode/javascript/javascript.min.js
 * - /npm/codemirror@5.40.2/mode/xml/xml.min.js
 * - /npm/codemirror@5.40.2/mode/python/python.min.js
 * - /npm/codemirror@5.40.2/mode/yaml/yaml.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
